import React from "react";
import { NavPad } from "../globalStyles";
function InvestorLogin(props) {
  return (
    <NavPad
      className="mycontainer"
      style={{
        height: "500px",
        display: "grid",
        placeContent: "center",
        transform: "translateY(-42px)",
      }}
    >
      <h1>Coming Soon</h1>
    </NavPad>
  );
}

export default InvestorLogin;
