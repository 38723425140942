import React from "react";
import Title from "../ui/Title";
import { InfoSec } from "./styles";

function Thesis(props) {
  return (
    <InfoSec className="py-5">
      <Title text="Thesis" />
      <p>
        In past few decades, Web 1.0 & Web 2.0 has drastically reduced the
        latency and cost at which people & institutions could trade value,
        information & collaborate with globally located counterparties they
        didn’t necessarily know, via trusted third parties.
        <br />
        <br />
        With Web 3.0, distributed machines and users are able to interact with
        data, value and other counterparties via peer-to-peer networks without
        the need for third parties. This ultimately creates an uncorrupted
        privacy centric computing fabric for the next wave of the web.
        <br />
        <br />
        In late 2008, a few months after the Global Financial Crisis hit the
        world, a white paper written by someone or a group by the name Satoshi
        Nakamoto was distributed to numerous software developer and cryptography
        mailing lists titled “Bitcoin - peer-to-peer electronic cash system”.
        The aim was to create a digital currency that could operate without any
        connection to a bank or central government, and to build a more
        transparent financial system. The advent of Bitcoin, Ethereum and other
        cryptocurrencies has led to the creation and operation of new global,
        decentralised networks that have been used by millions of people and
        institutions across the world to transfer trillions of dollars of value.
        <br />
        <br />
        It is a leap forward to open, trustless and permissionless networks
        where consumers own their own data and their own money without relying
        on a third party. Blockchain networks and Cryptocurrency projects open
        up access to financial services applications for users around the world.
        <br />
        <br />
        TeraSurge aims to support these open source Web 3.0 innovations and also
        provide product and technology assistance, support with business growth,
        and access to a network of experts and partners to maximize scale and
        growth.
      </p>
    </InfoSec>
  );
}

export default Thesis;
