import React from "react";
import Title from "../ui/Title";
import { InfoSec } from "./styles";

function AboutUs({ bigTitle }) {
  return (
    <InfoSec>
      <Title text="About" big={bigTitle} />
      <p>
        TeraSurge is a premier Crypto Hedge fund and Web 3.0 focused venture fund
        focused on producing exceptional returns for the most sophisticated
        global institutional investors.
        <br />
        <br />
        Our research process is driven by a tireless pursuit to understand how
        the world’s cryptocurrency market and other financial markets work —
        using cutting edge technology to validate and execute on our core
        strategies.
        <br />
        <br />
        Founded in 2017, we are a community of independent thinkers who share a
        commitment for excellence. By fostering a culture of openness,
        transparency, and inclusion, we strive to help real people. Through our
        research, we help fund breakthrough innovations in Web 3.0, education
        and a wide range of foundations.
      </p>
    </InfoSec>
  );
}

export default AboutUs;
