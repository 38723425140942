import React, { useEffect, useState } from "react";
import { BiChevronRight, BiChevronLeft } from "react-icons/bi";
import ArticleCard from "../Cards/ArticleCard";
import Title from "../ui/Title";
import Button from "../ui/Button";
import { Wrapper, Scroller, ScrollerWrapper } from "./styles";
import { fetchCollection } from "../../asyncDataCalls/fetchCollection";
import { useHistory } from "react-router";

function Blogs({ title }) {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    fetchCollection(setLoading, setBlogs, "blogs", 3);
  }, []);

  const handleScroll = (dir) => {
    const scrollSec = document.getElementById("scroller");
    scrollSec.scrollLeft = scrollSec.scrollLeft + dir * 310;
  };
  return (
    <Wrapper style={{ minHeight: "600px" }}>
      <Title text={title || "Blogs"} maxWidth="1100px" />
      <br />
      {loading ? (
        <p style={{ height: "400px", display: "grid", placeContent: "center" }}>
          Loading...
        </p>
      ) : (
        <>
          <ScrollerWrapper className="position-relative">
            <Scroller className="mt-4 d-flex" id="scroller">
              {Object.keys(blogs).map((blogId) => (
                <div
                  key={blogId}
                  className="col-12 col-lg-4 d-flex justify-content-center p-3"
                >
                  <ArticleCard article={blogs[blogId]} type="blogs" />
                </div>
              ))}
            </Scroller>
            <div className="right" onClick={() => handleScroll(1)}>
              <BiChevronRight />
            </div>
            <div className="left" onClick={() => handleScroll(-1)}>
              <BiChevronLeft />
            </div>
          </ScrollerWrapper>

          <div className="col-12 mt-4 d-flex justify-content-end">
            <Button
              text="View All Blogs"
              onclick={() => history.push("/blogs")}
            />
          </div>
        </>
      )}
    </Wrapper>
  );
}

export default Blogs;
