import React from "react";
import logoImg from "../../assets/images/footerLogo.png";
import { Link, Links, StyledFooter, FooterLink, FooterLogo } from "./styles";

import { funds } from "../../data/funds";
import ContactUs from "../Invest/ContactUs";

function Footer(props) {
  return (
    <StyledFooter>
      <div className="mycontainer d-flex flex-wrap justify-content-between align-items-start">
        <div className="col-12 col-lg-6 my-4">
          <FooterLogo className="navbar-nav" footer nunino>
            <img src={logoImg} alt="Arisa" width="176px" />
            <div className="separator"></div>
            <ContactUs forFotter />
          </FooterLogo>
        </div>
        <div className="col-12 col-lg-6 d-flex flex-wrap my-4">
          <div className="col-6">
            <Links className="navbar-nav" footer leftAlign>
              <FooterLink className="nav-item">
                <Link className="nav-link" to="/about">
                  About
                </Link>
              </FooterLink>
              <FooterLink className="nav-item">
                <Link className="nav-link" to="/invest">
                  Invest
                </Link>
              </FooterLink>
              <FooterLink className="nav-item">
                <Link className="nav-link" to="/blogs">
                  Blog
                </Link>
              </FooterLink>
              <FooterLink className="nav-item">
                <Link className="nav-link" to="/researchs">
                  Research
                </Link>
              </FooterLink>
              <FooterLink className="nav-item">
                <a
                  className="nav-link"
                  href=""
                  target="_blank"
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/arisa-io/jobs/",
                      "_blank"
                    )
                  }
                >
                  Careers
                </a>
              </FooterLink>
            </Links>
          </div>
          <div className="col-6">
            <Links className="navbar-nav" footer leftAlign>
              <FooterLink className="nav-item">
                <Link
                  className="nav-link"
                  to="/funds"
                  style={{ fontWeight: "bold" }}
                >
                  Funds
                </Link>
              </FooterLink>
              {Object.values(funds).map((fund) => (
                <FooterLink className="nav-item" key={fund.id}>
                  <Link className="nav-link" to={`/funds/${fund.id}`}>
                    {fund.title}
                  </Link>
                </FooterLink>
              ))}
            </Links>
          </div>
        </div>
      </div>
      <br />
      <div className="col-12 text-center">
        <p style={{ color: "white", fontSize: "15px" }}>
          &#169; 2021 TeraSurge Capital. All rights reserved.
        </p>
      </div>
    </StyledFooter>
  );
}

export default Footer;
