import React from "react";
import ArticleList from "../components/Articles/ArticleList";
import { NavPad } from "../globalStyles";

function Researchs(props) {
  return (
    <NavPad className="mycontainer pb-5">
      <br />
      <br />
      <br />
      <ArticleList collectionName="researchs" title={"Research"} />
    </NavPad>
  );
}

export default Researchs;
