import firebase from "firebase";

export const fetchCollection = (setLoading, setData, collectionName, limit) => {
  let ref;
  if (limit)
    ref = firebase
      .firestore()
      .collection(collectionName)
      .orderBy("rank")
      .limit(limit);
  else ref = firebase.firestore().collection(collectionName).orderBy("rank");

  setLoading(true);
  ref.onSnapshot((querySnapshot) => {
    const items = {};
    querySnapshot.forEach((doc) => {
      items[doc.id] = { ...doc.data(), id: doc.id };
    });
    setData(items);
    setLoading(false);
  });
};
