import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

const StyledFundCard = styled.div`
  position: relative;
  padding: 1rem;
  height: 100%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  cursor: pointer;
  & h4 {
    font-family: ${({ theme }) => theme.fonts.heading};
  }

  & .green-side {
    width: 12px;
    min-width: 12px;
    max-width: 12px;
    height: 90%;
  }
  &:hover .green-side {
    background: ${({ theme }) => theme.colors.lightGreen};
  }
`;
function FundCard({ fund }) {
  const history = useHistory();

  return (
    <StyledFundCard
      onClick={() => history.push(`/funds/${fund.id}`)}
      className="d-flex"
    >
      <div className="green-side"></div>
      <div style={{ flex: "1 1 auto", paddingLeft: "1rem" }}>
        <h4>{fund.title}</h4>
        <br />
        <p>{fund.desc}</p>
      </div>
    </StyledFundCard>
  );
}

export default FundCard;
