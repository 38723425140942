import React from "react";
import { useParams } from "react-router";
import Article from "../components/Articles/Article";

function Research() {
  const params = useParams();
  const id = params.id;

  return (
    <div>
      <Article type="researchs" id={id} />
    </div>
  );
}

export default Research;
