const theme = {
  colors: {
    primary: "#2f55d4",
    lightOrange: "#F0D1A3",
    lightGreen: "#2CAC9A",
    darkGreen: "#69AB80",
  },
  fonts: {
    heading: '"Spectral SC", serif',
    other: '"Nunito", sans-serif',
    navigations: '"Spectral SC", serif'
  },
  //fontSize are in rem
  fontSizes: {
    big: "3rem", //48px
    mediumBig: "1.25rem", //20px
    medium: "1.125rem", //18px
    small: "0.875rem", //14px
    verysmall: "0.75rem", //12px
  },
  screens: {
    mobile: "768px",
    tab: "992px",
  },
  navHeight: "95px",
  mobNavHeight: "72px",
};

export default theme;
