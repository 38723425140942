import React from "react";
import Title from "../ui/Title";
import Button from "../ui/Button";
import styled from "styled-components";
import { useHistory } from "react-router";

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & p {
    text-transform: capitalize;
    font-size: 17px;
  }
  & .heading {
    font-size: 19px;
    font-family: ${({ theme }) => theme.fonts.navigations};
    margin-bottom: 0.7rem;
  }
`;

function FundData({ fund }) {
  const history = useHistory();
  return (
    <div>
      <Title text={fund.title} classes="text-start" />
      <br />
      {fund.text.map((para, ind) => (
        <p key={ind} className="mb-3" style={{ textAlign: "justify" }}>
          {para}
        </p>
      ))}
      <div className="d-flex flex-wrap">
        {fund.cardsTitle && (
          <h4 className="col-12 text-center my-3">{fund.cardsTitle}</h4>
        )}
        {fund?.cards &&
          fund.cards.map((para, ind) => (
            <div className="col-12 col-md-6 p-3">
              <Card key={ind} className="mb-3">
                <p className="heading">{para.title}</p>
                <p>{para.desc}</p>
              </Card>
            </div>
          ))}
      </div>

      <div className="d-flex justify-content-end">
        <Button text="Invest" onclick={() => history.push("/invest")} />
      </div>
    </div>
  );
}

export default FundData;
