import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

const StyledArticleCard = styled.div`
  transition: all 0.3s ease;
  cursor: pointer;
  width: 100%;
  max-width: 330px;
  height: 100%;
  display: flex;
  flex-direction: column;
  & img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  & .footer {
    background: black;
    color: white;
    padding: 0.8rem;
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  & p {
    margin-bottom: 2rem;
    font-size: 1.125rem;
    line-height: 26px;
    font-family: ${({ theme }) => theme.fonts.heading};
  }
  & small {
    display: block;
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.navigations};
  }
  &:hover {
    transform: scale(1.05);
  }
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    &:hover {
      transform: scale(1);
    }
  }
`;
function ArticleCard({ article, removeDateandPublish, type }) {
  const history = useHistory();
  const handleCardClick = () => {
    history.push(`/${type}/${article.id}`);
  };
  return (
    <StyledArticleCard onClick={handleCardClick}>
      <img src={article.mainImg} alt={article.title} />
      <div className="footer">
        <p>{article.title}</p>
        {!removeDateandPublish && (
          <div>
            <small>{article.publishedby}</small>
            <small>{article.date}</small>
          </div>
        )}
      </div>
    </StyledArticleCard>
  );
}

export default ArticleCard;
