import React from "react";
import { Switch, Route, Redirect } from "react-router";
import { routes } from "./index";

function RouteComponent() {
  const routerender = [];
  routes.forEach((route, idx) => {
    routerender.push(
      <Route
        key={idx}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={(props) => (
          <>
            <route.component {...props} />
          </>
        )}
      />
    );
  });

  routerender.push(<Redirect to="/" key="redirect" />);

  return <Switch>{routerender}</Switch>;
}

export default RouteComponent;
