export const funds = {
  goldilocksfund: {
    id: "goldilocksfund",
    title: "Goldilocks Fund",
    desc: "Exposure to actively managed allocation in indicator based Altcoin spot trading strategy and quant algo based BTC derivatives trading strategy",
    text: [
      `TeraSurge Capital has been trading in cryptocurrency markets since early 2016. TeraSurge aims to provide its investors exceptional returns with limited risk by taking advantage of high volatility in cryptocurrency markets. `,
      `In Past 5 years after trading and testing over 50 different strategies in this nascent crypto market, we have identified our goldilocks strategy that allows us to thrive in bull and bear markets, particularly when volumes and volatility are high. `,
      `TeraSurge Goldilocks strategy provides a well diversified actively managed allocation in indicator based Altcoin spot trading strategy and quant algo based BTC derivatives trading strategy.`,
      `Altcoin spot trading strategy uses a thorough set of in-house on-chain and technical indicators with a series of complex sentiment analysis techniques based on machine learning algorithms to provide exceptional insights of flow of money around crypto markets.`,
      `Quant algo based BTC derivatives trading strategy is a fully automated sophisticated trading system which uses varied techniques ranging such as mean reversion, momentum & trend based techniques paired with complex machine learning techniques.`,
    ],
  },
  indexfund: {
    id: "indexfund",
    title: "Index Fund",
    desc: "Exposure to Crypto markets with a series of thoroughly researched Cryptocurrency baskets with varied risk exposure and growth potential",
    text: [
      `TeraSurge Crypto Index Fund is a series of thoroughly researched Cryptocurrency baskets with varied risk exposure and growth potential giving institutions and traditional market players an exposure into the crypto world as per their niche requirement. These are actively managed indexes with research based allocations in liquid cryptocurrencies.`,
    ],
    cards: [
      {
        title: "TeraSurge Large cap index",
        desc: "low risk | low growth",
      },
      {
        title: "TeraSurge DeFi index",
        desc: "medium risk | medium growth",
      },
      {
        title: "TeraSurge High Growth index",
        desc: "high risk | high growth",
      },
      {
        title: "TeraSurge NFT Index",
        desc: "Upcoming ",
      },
    ],
  },

  tokenfund: {
    id: "tokenfund",
    title: "Token Fund",
    desc: "Focused on Private Sale token investments in Web 3.0 and Blockchain Technology protocols and startups",
    text: [
      `TeraSurge Token Fund is a thesis-driven investment fund that invests in early stage cryptocurrencies and token projects in private sale with liquidity horizon of 1-3 years. `,
    ],
    cardsTitle: "Investment areas in focus",
    cards: [
      {
        title: "PoW",
        desc: "Proof of Work projects and its auxiliary use cases",
      },
      {
        title: "PoS",
        desc: "Layer 1 Proof of Stake projects",
      },
      {
        title: "Scale",
        desc: "Scalability solution projects including Layer 2 solutions",
      },
      {
        title: "DeFi",
        desc: "Decentralized Finance projects and its auxiliary use cases",
      },
      {
        title: "NFT",
        desc: "Metaverse projects, Non-fungible token projects",
      },
      {
        title: "DEX",
        desc: "Decentralized exchanges including automated market makers (AMMs) and IDO platforms",
      },
    ],
  },
  venturefund: {
    id: "venturefund",
    title: "Venture Fund",
    desc: "Early stage exposure to experiments and projects shaping the future of trillion dollar financial markets through Blockchain technology",
    text: [
      `TeraSurge Venture Fund offers its investors early stage exposure to blockchain companies shaping the future of trillion dollar financial markets. TeraSurge Venture Fund also invests in experiments which can potentially solve global financial problems and unique projects exploring blockchain technology’s capabilities and applications across various industries.`,
    ],
  },
};
