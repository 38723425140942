import React from "react";
import Blogs from "../components/Home/Blogs";
import dottedImage from "../assets/images/dotted.webp";
import Button from "../components/ui/Button";
import AboutUs from "../components/Home/AboutUs";
import { MetricsWrapper } from "../components/Home/styles";
function About() {
  return (
    <div>
      <div>
        <div
          className="d-flex flex-wrap pb-4"
          style={{
            backgroundImage: `url(${dottedImage})`,
            paddingTop: "7rem",
          }}
        >
          <div className="col-12 d-flex flex-column align-items-center mycontainer">
            <AboutUs bigTitle />
          </div>
        </div>
        <MetricsWrapper className="d-flex flex-column align-items-center">
          <div className="mycontainer d-flex flex-column align-items-center justify-content-center">
            <h3 className="text-center">Join the TeraSurge Team</h3>
            <br />
            <p className="text-center">
              We work towards creating prodigious returns for the most <br />
              sophisticated global institutional investors.
            </p>
            <Button
              text="Join the Team"
              onclick={() =>
                window.open(
                  "https://www.linkedin.com/company/arisa-io/jobs/",
                  "_blank"
                )
              }
            />
          </div>
        </MetricsWrapper>
        <br />
        <div className="mycontainer">
          <Blogs />
        </div>
        <br />
      </div>
    </div>
  );
}

export default About;
