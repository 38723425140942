import React from "react";
import styled from "styled-components";
import ArticleCard from "../Cards/ArticleCard";

const Heading = styled.h4`
  font-family: ${({ theme }) => theme.fonts.navigations};
`;
function ArticlesSidebar({ articles, publishedby, date, id, type }) {
  return (
    <div>
      <Heading className="mt-5">
        {publishedby} <br /> {date}
      </Heading>
      <br />
      {Object.keys(articles).map((articleId) => {
        if (articleId === id) return <></>;
        return (
          <div key={articleId} className="my-3">
            <ArticleCard
              article={articles[articleId]}
              removeDateandPublish
              articleId={articleId}
              type={type}
            />
          </div>
        );
      })}
    </div>
  );
}

export default ArticlesSidebar;
