import React from "react";
import styled from "styled-components";
import { Controller, Scene } from "react-scrollmagic";
import { BackgroundVideo } from "./styles";
import screenshot from "../../assets/images/screenshot.webp";

const StyledVideo = styled.div`
  overflow: hidden;
  position: relative;
  z-index: -1;
  & .navHeight {
    height: ${({ theme }) => theme.navHeight};
  }

  .panel {
    height: 800px;
    width: 100vw;
    text-align: center;
    position: relative;
    transform: ${({ theme }) => "translateY(" + theme.navHeight + ")"};
    overflow: hidden;
  }
  .panel video {
  }
  .panel h3 {
    color: white;
  }

  .panel.blue {
    background-color: #3883d8;
  }
  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    & .panel {
      display: none;
    }
  }
`;

export const Opaque = styled.div`
  position: absolute;
  top: -7%;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
`;
const MobileView = styled.div`
  display: none;
  background: transparent;
  position: relative;
  & img {
    width: 100%;
    object-fit: contain;
  }
  & h3 {
    position: absolute;
    top: 60%;
    left: 0;
    width: 100%;
    color: white;
    font-size: 12px;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    padding: 1rem;
  }
  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    display: block;
  }
`;
const Video = () => (
  <StyledVideo>
    <Controller globalSceneOptions={{ triggerHook: "onLeave" }}>
      <Scene pin>
        <div>
          <div className="panel">
            <BackgroundVideo
              src={`https://firebasestorage.googleapis.com/v0/b/arisa-crypto.appspot.com/o/building2.mov?alt=media&token=0284fd6e-5668-4a39-82ac-d1b2b421a533`}
              poster={screenshot}
              loop
              autoPlay
              muted
              playsinline
              className="video"
            />
            <Opaque>
              <h2>The Future of Money is Digital Currency</h2>
            </Opaque>
          </div>
          <MobileView>
            <div className="navHeight"></div>
            <img src={screenshot} alt="TeraSurge" />
            <h3>The Future of Money is Digital Currency</h3>
          </MobileView>
        </div>
      </Scene>
    </Controller>
  </StyledVideo>
);

export default Video;
