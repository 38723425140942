import React from "react";
import Title from "../ui/Title";
import styled from "styled-components";
import { useHistory } from "react-router";
import { Wrapper } from "../Articles/ArticalData";

const FundName = styled.p`
  margin-bottom: 0.6rem;
  padding-bottom: 0.6rem;
  position: relative;
  cursor: pointer;
  transition: all 0.3 ease;
  border-bottom: ${({ active }) =>
    active ? "none" : "1px solid rgba(0, 0, 0, 0.3)"};
  & span {
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 5px;
    background: ${({ active, theme }) =>
      active ? theme.colors.lightGreen : "transparent"};
  }
  &:last-child {
    border: none;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.lightGreen};
  }
`;
function FundSidebar({ funds, activeFund }) {
  const history = useHistory();
  return (
    <Wrapper>
      <br />
      <Title text="Other Funds" classes="text-start my-4" small />

      {Object.keys(funds).map((fundId) => (
        <FundName
          key={fundId}
          active={activeFund === fundId}
          onClick={() => history.push(`/funds/${fundId}`)}
        >
          {funds[fundId].title} <span></span>
        </FundName>
      ))}
    </Wrapper>
  );
}

export default FundSidebar;
