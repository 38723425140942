import styled, { keyframes } from "styled-components";
import { ReactComponent as IntroSVG } from "../../assets/icons/triangle.svg";

export const BackgroundVideo = styled.video`
  position: absolute;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    display: none;
  }
`;
export const Text = styled.div`
  text-align: left;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    text-align: center;
    justify-content: center;
    align-items: center;
    transform: translateY(-30px);
  }
`;
const rotateZ = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  20% {
    transform: rotateZ(20deg);
  }
  40% {
    transform: rotateZ(-20deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
`;
export const IconContainer = styled.div`
  width: 303px;
  height: 264px;
  margin: auto;
  position: relative;
  & .logoMain {
    width: 303px;
    height: 264px;
  }
  & .triangle_wrap {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -30px);
  }
  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    width: 250px;
    height: 200px;

    margin-top: 5.5rem;
    & .logoMain {
      width: 250px;
      height: 200px;
    }
  }
`;
export const AnimatedIntro = styled(IntroSVG)`
  width: 70px;
  height: 63px;
  margin: auto;

  /* transform: rotateX(95deg); */
  animation: ${rotateZ} 1.2s normal forwards ease-in-out;
  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    width: 50px;
    height: 43px;
  }
`;

export const InvestButton = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(50%);
  width: 100%;
  max-width: 1200px;
  margin-left: 15rem;
  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    left: 50%;
    transform: translate(-50%, 50%);
    margin-left: 0;
  }
`;

export const InfoSec = styled.div`
  max-width: 766px;
  min-height: 500px;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & p {
    text-align: justify;
    margin-top: 2rem;
  }
`;

export const MetricsWrapper = styled.div`
  & .show-on-mobile {
    display: none;
  }
  & .magnifier {
    display: grid;
    place-content: center;
  }
  background: #f8f8f8;
  padding: 3rem 0;
  & .mycard {
    margin: 1rem 0;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.8rem;
    h2 {
      color: ${({ theme }) => theme.colors.lightGreen};
      margin: 0 0.7rem;
      font-weight: 700;
      font-family: ${({ theme }) => theme.fonts.other};
    }
  }
  & .small {
    max-width: 230px;
  }
  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    & .show-on-mobile {
      display: block;
    }
    & .magnifier {
      display: none;
    }
    & .mycard {
      display: flex;
      justify-content: center;
    }
    & .mycard,
    & .small {
      max-width: 100%;
      width: 100%;
      margin: 1rem 2rem;
    }
  }
`;
export const ScrollerWrapper = styled.div`
  & .right,
  .left {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    height: 40px;
    width: 40px;
    place-content: center;
    background: rgba(255, 255, 255);
    & svg {
      height: 30px;
      width: 30px;
    }
    display: none;
  }
  & .left {
    left: 0;
  }
  & .right {
    right: 0;
  }
  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    & .right,
    .left {
      display: grid;
    }
  }
`;
export const Scroller = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
`;

export const Wrapper = styled.div`
  padding: 3rem 0;
  padding-bottom: 6rem;
  max-width: 1200px;
  margin: 0 auto;
  & .mycard {
    margin: 1rem 0;
    max-width: 311px;
    cursor: pointer;
    h4 {
      font-family: ${({ theme }) => theme.fonts.heading};
      margin: 1rem 0;
      font-weight: 400;
    }
  }
`;
