import React from "react";
import ArticleList from "../components/Articles/ArticleList";
import { NavPad } from "../globalStyles";

function Blogs() {
  return (
    <NavPad className="mycontainer pb-5">
      <br />
      <br />
      <br />
      <ArticleList collectionName="blogs" title={"Blogs"} />
    </NavPad>
  );
}

export default Blogs;
