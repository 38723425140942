import React, { useEffect, useState } from "react";
import ArticleData from "./ArticalData";
import ArticleSidebar from "./ArticleSidebar";
import { fetchCollection } from "../../asyncDataCalls/fetchCollection";

import { NavPad, HideOnMobile } from "../../globalStyles";
import Blogs from "../Home/Blogs";

function Article({ type, id }) {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCollection(setLoading, setArticles, type);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  if (!id || !articles[id]) {
    return (
      <NavPad className="mycontainer">
        <p style={{ height: "600px", display: "grid", placeContent: "center" }}>
          No Data
        </p>
      </NavPad>
    );
  }
  return (
    <NavPad className="mycontainer">
      <br />
      <br />
      <br />
      <div className="w-100 d-flex flex-wrap">
        {loading ? (
          <p className="col-12 text-center">Loading...</p>
        ) : (
          <>
            <div className="col-12 col-md-8">
              <ArticleData article={articles[id]} />
            </div>
            <div className="col-0 col-md-1"></div>
            <HideOnMobile className="col-0 col-md-3 px-1">
              <ArticleSidebar
                articles={articles}
                publishedby={articles[id].publishedby}
                date={articles[id].date}
                id={id}
                type={type}
              />
            </HideOnMobile>
          </>
        )}
      </div>
      <Blogs title="Blogs" />
    </NavPad>
  );
}

export default Article;
