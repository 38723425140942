import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const StyledNavbar = styled.nav`
  padding: 0.5rem 1rem;
  color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${({ theme }) => theme.navHeight};
  display: flex;
  align-items: center;
  background: ${({ highlight }) => (highlight ? "white" : "transparent")};
  box-shadow: ${({ highlight }) =>
    highlight && "rgb(146 161 176 / 15%) 0px 1px 4px;"};
  z-index: 1000;
  & .hamburger {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    & .hamburger {
      display: block;
      cursor: pointer;
    }
  }
  @media (max-width: ${({ theme }) => theme.screens.mobile}) {
    & img {
      height: 50px;
    }
  }
`;
export const Toggler = styled.div`
  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    width: 100%;
    transition: all 0.4s ease;
    overflow: hidden;
    height: ${({ show, theme }) =>
      show ? `calc(90vh - ${theme.navHeight})` : "0px"};
    opacity: ${({ show }) => (show ? "1" : "0")};
    transition: all 0.4s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & ul {
      margin-left: 0 !important;
    }
  }
`;
export const Links = styled.ul`
  margin-left: auto;
  display: flex;
  align-items: center;
  list-style: none;
  font-family: ${({ theme, nunino }) =>
    nunino ? theme.fonts.other : theme.fonts.navigations};
  ${({ footer }) =>
    footer &&
    `
  flex-direction: column !important;
  align-items:start;
  `}
  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    text-align: center;
    align-items: ${({ leftAlign }) => (leftAlign ? "flex-start" : "center")};
  }
`;

export const StyledLink = styled.li`
  position: relative;
  & a,
  & p {
    font-style: normal;
    font-weight: normal;
    margin: 0 1.7rem;
    transition: all 0.3s ease;
    text-align: center;
    cursor: pointer;
    color: black !important;
  }
  & a:hover,
  & p:hover {
    color: ${({ theme }) => theme.colors.lightGreen} !important;
  }
  & .active {
    color: ${({ theme }) => theme.colors.lightGreen} !important;
  }
`;
export const Link = styled(NavLink)``;
// export const StyledFooter = styled.footer`
//   background: ${({ theme }) => theme.colors.blue};
//   padding: 5rem 2rem;
//   filter: drop-shadow(0px 10px 25px rgba(0, 0, 0, 0.25));
//   @media (max-width: ${({ theme }) => theme.screens.mobile}) {
//     & img {
//       height: 50px;
//     }
//   }
// `;

// export const Wrapper = styled.div`
//   display: flex;
//   justify-content: space-around;
//   flex-wrap: wrap;
//   & a {
//     text-align: left;
//   }
//   & img {
//     align-self: flex-start;
//   }
//   & ul {
//     list-style: none;
//   }

//   @media (max-width: ${({ theme }) => theme.screens.mobile}) {
//     flex-direction: column;
//     justify-content: stretch;
//     align-items: center;
//     & img {
//       align-self: center;
//     }
//     & a {
//       text-align: center;
//     }
//   }
// `;
// export const Line = styled.div`
//   width: 3px;
//   background: white;
//   color: ${({ theme }) => theme.colors.grey};
//   margin-left: 2rem;
//   margin-bottom: 2rem;
//   @media (max-width: ${({ theme }) => theme.screens.mobile}) {
//     height: 3px;
//     max-width: 150px;
//     width: 100%;
//     margin: 2rem 0;
//   }
// `;

// export const Icons = styled.div`
//   color: white;
//   & svg {
//     margin: 1rem;
//     transition: all 0.3s ease;
//     cursor: pointer;
//   }
//   & svg:hover {
//     color: ${({ theme }) => theme.colors.light}!important;
//   }
//   & p {
//     font-size: ${({ theme }) => theme.fontSizes.small};
//   }
// `;

// export const User = styled.div`
//   position: relative;
//   margin-left: 1.7rem;
//   cursor: pointer;
//   display: inline-block;

//   & .userimg {
//     height: 64px;
//     width: 64px;
//     border-radius: 50%;
//     overflow: hidden;
//   }
//   & img {
//     width: 100%;
//     object-fit: cover;
//   }
//   @media (max-width: ${({ theme }) => theme.screens.mobile}) {
//     margin: 0;
//     margin-top: 0.6rem;
//     & .userimg {
//       height: 46px;
//       width: 46px;
//     }
//   }
// `;
// export const DropDown = styled.ul`
//   display: none;
//   position: absolute;
//   top: 100%;
//   left: 0;
//   transform: translateX(-70%);
//   list-style: none;
//   background: white;
//   width: 200px;
//   padding: 0;
//   border-radius: 5px;
//   overflow: hidden;
//   & li {
//     width: 100%;
//     text-align: left;
//     padding: 0.5rem 0.7rem;
//     border-bottom: 1px solid #e5e5e5;
//   }
//   & li:hover {
//     background: ${({ theme }) => theme.colors.light};
//   }
//   & li:last-child {
//     border: none;
//   }
//   @media (max-width: ${({ theme }) => theme.screens.mobile}) {
//     left: 50%;
//     transform: translateX(-50%);
//   }
// `;
export const DropDown = styled.div`
  list-style: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  width: 220px;
  padding: 0;
  margin: 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  display: ${({ show }) => (show ? "block" : "none")};
  & span a {
    display: block;
    width: 100%;
    padding: 0.6rem 0.7rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    font-size: 17px;
  }
  & span:hover a,
  & .active {
    border-color: ${({ theme }) => theme.colors.lightGreen};
  }
  & a:after {
    height: 5px;
    width: 100%;
  }
  & span:last-child {
    border: none;
  }
  & a {
    margin: 0 !important;
    text-decoration: none;
  }

  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    display: none;
  }
`;
export const FooterLink = styled(StyledLink)`
  & a,
  & p {
    color: white !important;
    margin: 0 !important;
    line-height: 16px;
    text-decoration: none;
    & svg {
      margin-right: 0.4rem;
    }
  }
`;
export const FooterLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & img {
    margin-top: 5px;
  }
  & .separator {
    width: 1px;
    height: 138px;
    background: white;
    margin-right: 1rem;
    margin-left: 1.5rem;
  }
  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    justify-content: center;
  }
`;
export const StyledFooter = styled.footer`
  background: black;
  padding: 4rem 0;
  width: 100%;
  & img {
    height: 80px;
  }
`;
