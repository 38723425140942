import React, { useEffect, useState } from "react";
import ArticleCard from "../Cards/ArticleCard";
import Title from "../ui/Title";
import { fetchCollection } from "../../asyncDataCalls/fetchCollection";

function Research({ title, collectionName }) {
  const [article, setArticle] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCollection(setLoading, setArticle, collectionName);
  }, []);

  return (
    <>
      <Title text={title} maxWidth="100%" big />
      <br />
      {loading ? (
        <p style={{ height: "400px", display: "grid", placeContent: "center" }}>
          Loading...
        </p>
      ) : (
        <>
          <div className="mt-4 d-flex flex-wrap">
            {Object.keys(article).map((articleId) => (
              <div
                key={articleId}
                className="col-12 col-lg-4 d-flex justify-content-center p-3"
              >
                <ArticleCard
                  article={article[articleId]}
                  type={collectionName}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default Research;
