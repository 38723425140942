import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import Button from "../ui/Button";
import logo from "../../assets/images/logo.png";
import {
  DropDown,
  Links,
  StyledLink,
  Link,
  StyledNavbar,
  Toggler,
} from "./styles";
import { funds } from "../../data/funds";
import { useHistory } from "react-router";

function Navbar() {
  // const history = useHistory();
  // const [dropdown, setDropdown] = useState(false);
  const [highlight, setHighlight] = useState(false);
  const [show, setShow] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const history = useHistory();
  const animateOnScroll = () => {
    if (window.scrollY >= "1") {
      setHighlight(true);
    } else {
      setHighlight(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", animateOnScroll);
    return () => {
      window.removeEventListener("scroll", animateOnScroll);
    };
  });

  return (
    <StyledNavbar
      className="navbar navbar-expand-lg navbar-light"
      highlight={highlight}
    >
      <div
        className="mycontainer d-flex justify-content-between align-items-center"
        style={{ flexWrap: "inherit", position: "relative" }}
      >
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="TeraSurge Capital" height="60px" />
        </Link>
        <GiHamburgerMenu
          className="hamburger"
          color="black"
          size={25}
          onClick={() => setShow((p) => !p)}
        />
        <Toggler show={show}>
          <Links className="navbar-nav header-navbar">
            <StyledLink className="nav-item" onClick={() => setShow(false)}>
              <Link className="nav-link" to="/about" activeClassName="active">
                About
              </Link>
            </StyledLink>
            <StyledLink className="nav-item" onClick={() => setShow(false)}>
              <Link className="nav-link" to="/invest" activeClassName="active">
                Invest
              </Link>
            </StyledLink>
            <StyledLink
              className="nav-item"
              onMouseOver={() => setShowDropDown(true)}
              onMouseLeave={() => setShowDropDown(false)}
            >
              <Link
                className="nav-link"
                to="/funds"
                activeClassName="active"
                onClick={() => setShow(false)}
              >
                Funds
              </Link>
              <DropDown show={showDropDown}>
                {Object.values(funds).map((fund) => (
                  <span key={fund.id}>
                    <Link to={`/funds/${fund.id}`}>{fund.title}</Link>
                  </span>
                ))}
              </DropDown>
            </StyledLink>
            <StyledLink className="nav-item" onClick={() => setShow(false)}>
              <Link className="nav-link" to="/blogs" activeClassName="active">
                Blog
              </Link>
            </StyledLink>
            <StyledLink className="nav-item" onClick={() => setShow(false)}>
              <Link
                className="nav-link"
                to="/researchs"
                activeClassName="active"
              >
                Research
              </Link>
            </StyledLink>
            <div style={{ margin: "0 1.7rem"}}>
              <Button
                text="Investor Login"
                onclick={() => {
                  setShow(false);
                  history.push("/investor_login");
                }}
              />
            </div>
          </Links>
        </Toggler>
      </div>
    </StyledNavbar>
  );
}

export default Navbar;
