import React from "react";
import Magnifier from "react-magnifier";
import Title from "../ui/Title";
import { MetricsWrapper } from "./styles";
import metricsImage from "../../assets/images/metrics.png";

function Metrics(props) {
  return (
    <MetricsWrapper>
      <div className="magnifier">
        <Magnifier src={metricsImage} width={1100} />
      </div>
      <div className="show-on-mobile">
        <Title text="TeraSurge at a glimpse" />
        <br />
        <br />
        <div
          className="w-100 d-flex flex-wrap justify-content-center"
          style={{ maxWidth: "1000px", margin: "0 auto" }}
        >
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <div className="mycard small">
              <h2>4</h2>
              <p>Fund strategies</p>
            </div>
          </div>
          
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <div className="mycard small">
              <h2>12</h2>
              <p>Early stage investments</p>
            </div>
          </div>
        </div>
      </div>
    </MetricsWrapper>
  );
}

export default Metrics;
