import React from "react";
import Title from "../ui/Title";
import styled from "styled-components";

const Heading = styled.p`
  font-family: ${({ theme }) => theme.fonts.heading};
  display: ${({ showOnMobile }) => showOnMobile && "none"};

  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    display: block;
    font-family: ${({ theme, showOnMobile }) =>
      showOnMobile && theme.fonts.navigations};
  }
`;
export const Wrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    transform: translateY(-90px);
  }
`;
const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;
function ArticleData({ article }) {
  return (
    <Wrapper>
      <Heading className="mt-5" showOnMobile>
        {article.publishedby} <br /> {article.date}
      </Heading>
      <Title text={article.title} classes="text-start" big />
      <br />
      {article.data?.map((sec, ind) => (
        <div key={ind} className="mb-5">
          <Heading>{sec?.heading}</Heading>
          {sec?.text?.map((para, ind) => (
            <p key={ind} className="mb-4" style={{ textAlign: "justify" }}>
              {para}
            </p>
          ))}
          <Image src={sec.img} />
        </div>
      ))}
    </Wrapper>
  );
}

export default ArticleData;
