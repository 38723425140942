import React from "react";
import { useParams } from "react-router";
import Article from "../components/Articles/Article";

function Blog() {
  const params = useParams();
  const id = params.id;

  return (
    <div>
      <Article type="blogs" id={id} />
    </div>
  );
}

export default Blog;
