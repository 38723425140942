import emailjs from "emailjs-com";

export const sendemail = (
  data,
  onComplete,
  templateId = "template_5k919kb"
) => {
  emailjs
    .send(
      "service_xrovt7u",
      templateId,
      data,
      process.env.REACT_APP_EMAILJS_USERID
    )
    .then(
      (result) => {
        if (onComplete) {
          onComplete();
        }
      },
      (error) => {
        console.log(error.text);
      }
    );
};
