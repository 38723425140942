import React from "react";
import Navbar from "./components/Navigation/Navbar";
import RouteComponent from "./routes/RouteComponent";

import { AppWrapper } from "./globalStyles";
import "./App.css";
import Footer from "./components/Navigation/Footer";
import firebase from "./firebase";
function App() {
  return (
    <AppWrapper className="App">
      <Navbar />
      <RouteComponent />
      <Footer />
    </AppWrapper>
  );
}

export default App;
