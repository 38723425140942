import React from "react";
import { FullPage } from "../../globalStyles";
import Button from "../ui/Button";
import { ReactComponent as IntroSVG } from "../../assets/icons/introLogo.svg";
import { AnimatedIntro, IconContainer, InvestButton, Text } from "./styles";
import { useHistory } from "react-router";



function Intro(props) {
  const history = useHistory();
  return (
    <div className="position-relative w-100">
      <FullPage className="mycontainer d-flex flex-wrap-reverse align-items-center justify-content-between">
        <Text className="col-12 col-lg-8 d-flex flex-column">
          <h2 >
            An Innovation Focused Blockchain & Crypto <br/> Hedge Fund
          </h2>
          <br />
          <Button text="Funds" onclick={() => history.push("/funds")} />
        </Text>

        <div className="col-12 col-lg-4 d-flex justify-content-center mb-5">
        
          <div className="head-image">
            <img src={require('../../assets/images/ii.png').default} alt='mySvgImage' />
          </div>
          
        </div>
      </FullPage>
    </div>
  );
}

export default Intro;
