import React from "react";
import { FiMail, FiTwitter } from "react-icons/fi";
import { RiLinkedinLine } from "react-icons/ri";
import Title from "../ui/Title";
import styled, { css } from "styled-components";
const Wrapper = styled.div`
  & p {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    & span {
      display: flex;
      align-items: flex-start;
    }
    & .heading {
      display: block;
      font-size: 15px;
    }
    & svg:not(.exclude) {
      transform: translateY(2px);
    }
  }
  & .exclude svg {
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: ${({ theme }) => theme.colors.lightGreen};
    }
  }
  margin-top: 3rem;
  ${({ forFotter }) =>
    forFotter &&
    css`
      margin-top: 0;
      & p {
        color: white;
      }
    `}
`;
function ContactUs({ forFotter }) {
  return (
    <Wrapper forFotter={forFotter}>
      {!forFotter && (
        <Title text="Contact Us" classes="text-start mt-4" small />
      )}

      <div>
        {!forFotter && <br />}
        <p className="mb-2">
          <span className="heading">Investment Enquiries</span>{" "}
          <span>
            <FiMail size={22} style={{ marginRight: "0.4rem" }} />
            invest@terasurge.capital
          </span>
        </p>
        <p className="mb-2">
          <span className="heading">General Enquiries</span>{" "}
          <span>
            <FiMail size={22} style={{ marginRight: "0.4rem" }} />
            hello@terasurge.capital
          </span>
        </p>
        <p className="mb-2">
          {" "}
          <span className="exclude">
            <FiTwitter
              size={22}
              style={{ marginRight: "0.4rem" }}
              onClick={() =>
                window.open("https://twitter.com/terasurge", "_blank")
              }
            />
            <RiLinkedinLine
              size={26}
              style={{ marginRight: "0.4rem", transform: "translateY(-1.5px)" }}
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/arisa-io",
                  "_blank"
                )
              }
            />
          </span>
        </p>
      </div>
    </Wrapper>
  );
}

export default ContactUs;
