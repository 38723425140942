import React, { useState } from "react";
import styled from "styled-components";

const StyledButton = styled.p`
  position: relative;
  display: block;
  overflow: hidden;
  margin: 1rem auto;
  text-align: center;
  min-width: 150px;
  text-decoration: none;
  box-shadow: inset 0px 0px 0px 1px black;
  padding: 10px 14px;
  color: black !important;
  z-index: 1;
  font-family: ${({ theme }) => theme.fonts.navigations};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  & span {
    position: absolute;
    display: ${({ disabled }) => (disabled ? "none" : "block")};
    width: 0;
    height: 0;
    top: ${({ top }) => top};
    left: ${({ left }) => left};
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.lightGreen};
    -webkit-transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  &:hover {
    color: black;
  }
  &:hover span {
    width: 225%;
    height: 562.5px;
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.darkGreen};
  }
`;
function Button({ text, onclick, disabled }) {
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const handleMouse = (e) => {
    var rect = e.target.getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    var y = e.clientY - rect.top; //y position within the element.
    setTop(y + "px");
    setLeft(x + "px");
  };
  return (
    <div className="position-relative">
      <StyledButton
        top={top}
        left={left}
        disabled={disabled}
        onClick={() => {
          if (!disabled) {
            onclick && onclick();
          }
        }}
        id="btn-posnawr"
        onMouseEnter={handleMouse}
        onMouseLeave={handleMouse}
      >
        {text}
        <span></span>
      </StyledButton>
    </div>
  );
}

export default Button;
