import React from "react";
import ContactUs from "../components/Invest/ContactUs";
import Title from "../components/ui/Title";
import InvestForm from "../components/Invest/InvetForm";
import { NavPad } from "../globalStyles";
function Invest(props) {
  return (
    <NavPad className="mycontainer">
      <br />
      <br />
      <div className="d-flex flex-wrap">
        <div className="col-12 col-md-7">
          <Title text="Invest with us" classes="text-start" big />
          <br />
          <InvestForm />
        </div>
        <div className="col-0 col-md-1"></div>
        <div className="col-12 col-md-4">
          <ContactUs />
        </div>
      </div>
      <br />
      <br />
    </NavPad>
  );
}

export default Invest;
