import React from "react";
import Title from "../ui/Title";
import Button from "../ui/Button";
import { Wrapper } from "./styles";
import { useHistory } from "react-router";
import { funds as fundsData } from "../../data/funds";

function Funds(props) {
  const history = useHistory();
  const funds = Object.values(fundsData).slice(0, 3);

  return (
    <Wrapper>
      <Title text="Funds" maxWidth="1100px" />
      <div className="d-flex flex-wrap">
        {funds.map((fund) => (
          <div
            className="col-12 col-lg-4 d-flex justify-content-center p-3"
            key={fund.id}
          >
            <div
              className="mycard"
              onClick={() => history.push(`funds/${fund.id}`)}
            >
              <h4>{fund.title}</h4>
              <p>{fund.desc}</p>
            </div>
          </div>
        ))}
        <div className="col-12 mt-3 position-relative">
          <div
            style={{
              position: "absolute",
              top: 0,
              right: "40px",
              zIndex: "10",
            }}
          >
            <Button
              text="View All Funds"
              onclick={() => history.push("/funds")}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Funds;
