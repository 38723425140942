import React from "react";
import styled, { css } from "styled-components";

const StyledTitle = styled.h3`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || "766px"};
  margin: 0 auto;
  text-align: center;
  padding: 0.8rem 0;
  padding-bottom: 1.2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  font-family: ${({ small, theme }) => (small ? theme.fonts.navigations : "")};
  font-size: ${({ small, big }) => (small ? "1.5rem" : big ? "4rem" : "")};
  font-weight: ${({ small }) => small && "bold"};

  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    font-size: ${({ small, big }) => (small ? "1.25rem" : big ? "2rem" : "")};
  }
`;

function Title({ text, maxWidth, classes, small, big }) {
  return (
    <StyledTitle
      maxWidth={maxWidth}
      className={classes}
      small={small}
      big={big}
    >
      {text}
    </StyledTitle>
  );
}

export default Title;
