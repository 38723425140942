import React from "react";
import AboutUs from "../components/Home/AboutUs";
import Blogs from "../components/Home/Blogs";
import Funds from "../components/Home/Funds";
import Intro from "../components/Home/Intro";
import Metrics from "../components/Home/Metrics";
import Thesis from "../components/Home/Thesis";
import Video from "../components/Home/Video";
import dottedImage from "../assets/images/dotted.webp";

function Home(props) {
  return (
    <>
      <div className="position-relative">
        <div
          style={{
            backgroundImage: `url(${dottedImage})`,
            zIndex: "-2",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        ></div>
        <Intro />
        <div className="mycontainer">
          <AboutUs />
        </div>
        <Video />
      </div>

      <div style={{ background: "white" }}>
        <div className="mycontainer">
          <Thesis />
        </div>
        <Metrics />
        <br />
        <br />
        <br />
        <div className="mycontainer">
          <Funds />

          <Blogs title="Blogs" />
        </div>
      </div>
    </>
  );
}

export default Home;
