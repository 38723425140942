import React from "react";
import { useParams } from "react-router";
import FundData from "../components/Fund/FundData";
import FundSidebar from "../components/Fund/FundSidebar";

import { NavPad } from "../globalStyles";
import Blogs from "../components/Home/Blogs";
import { funds } from "../data/funds";

function Fund() {
  const params = useParams();
  const id = params.id;

  return (
    <NavPad className="mycontainer">
      <br />
      <br />
      <br />
      {!funds[id] ? (
        <p>No Information available</p>
      ) : (
        <div className="w-100 d-flex flex-wrap-reverse">
          <div className="col-12 col-md-8">
            <FundData fund={funds[id]} />
          </div>
          <div className="col-0 col-md-1"></div>
          <div className="col-12 col-md-3 px-1">
            <FundSidebar funds={funds} activeFund={id} />
          </div>
        </div>
      )}

      <Blogs title="Blogs" />
    </NavPad>
  );
}

export default Fund;
