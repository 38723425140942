import styled from "styled-components";

export const AppWrapper = styled.div``;
export const NavPad = styled.div`
  padding-top: ${({ theme }) => theme.navHeight};
`;
export const FullPage = styled.div`
  min-height: 100vh;
`;
export const StyledInputGroup = styled.div`
  position: relative;
  & input,
  & select {
    outline: none;
    border: none;
    background: #f8f8f8;
    padding: 0.8rem 0.6rem;
    width: 100%;
    border: 1px solid transparent;
    border-color: ${({ error }) => error && "red !important"};
  }
  & input:focus {
    border-color: ${({ theme }) => theme.colors.lightGreen};
  }
  & label {
    color: #8b878d;
    font-family: ${({ theme }) => theme.fonts.navigations};
  }
  & option {
    width: 100%;
    background: #f8f8f8;
    padding: 0.6rem;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 15px;
`;
// export const Button = styled.button`
//   font-family: ${({ theme }) => theme.fonts.navigations};

//   letter-spacing: 1px;
//   padding: 5px 14px;
//   outline: 0;
//   border: 1px solid black;
//   cursor: pointer;
//   position: relative;
//   background: transparent;
//   min-width: 150px;
//   &::after {
//     content: "";
//     background-color: ${({ theme }) => theme.colors.lightGreen};
//     width: 100%;
//     z-index: -1;
//     position: absolute;
//     height: 100%;
//     top: 7px;
//     left: 7px;
//     transition: 0.2s;
//     display: none;
//   }
//   &:hover::after {
//     display: block;
//   }

//   &:active:hover::after {
//     top: 0px;
//     left: 0px;
//   }
// `;

export const HideOnMobile = styled.div`
  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    display: none;
  }
`;
