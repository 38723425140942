import React from "react";
import FundCard from "../components/Cards/FundCard";
import { NavPad } from "../globalStyles";
import Title from "../components/ui/Title";
import Blogs from "../components/Home/Blogs";
import { funds as fundsData } from "../data/funds";

function Funds(props) {
  const funds = Object.values(fundsData);

  return (
    <NavPad className="mycontainer">
      <br />
      <br />
      <div className="d-flex flex-wrap">
        <div className="col-12">
          <Title text="Funds" maxWidth="100%" big />
          <br />
          <br />
          <div className="d-flex flex-wrap">
            {funds.map((fund) => (
              <div className="col-12 col-md-6 p-3" key={fund.id}>
                <FundCard fund={fund} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <br />
      <Blogs title="Blogs" />
    </NavPad>
  );
}

export default Funds;
