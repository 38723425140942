import About from "../container/About";
import Blogs from "../container/Blogs";
import Fund from "../container/Fund";
import Funds from "../container/Funds";
import Home from "../container/Home";
import Invest from "../container/Invest";
import InvestorLogin from "../container/InvestorLogin";
import Researchs from "../container/Researchs";
import Article from "../components/Articles/Article";
import Blog from "../container/Blog";
import Research from "../container/Research";

export const routes = [
  {
    name: "Home",
    component: Home,
    private: false,
    path: "/",
    exact: true,
    hideonauth: false,
  },
  {
    name: "Blog",
    component: Blog,
    private: false,
    path: "/blogs/:id",
    exact: true,
    hideonauth: false,
  },
  {
    name: "Research",
    component: Research,
    private: false,
    path: "/researchs/:id",
    exact: true,
    hideonauth: false,
  },
  {
    name: "Invest",
    component: Invest,
    private: false,
    path: "/invest",
    exact: true,
    hideonauth: false,
  },
  {
    name: "Funds",
    component: Funds,
    private: false,
    path: "/funds",
    exact: true,
    hideonauth: false,
  },
  {
    name: "Fund",
    component: Fund,
    private: false,
    path: "/funds/:id",
    exact: true,
    hideonauth: false,
  },
  {
    name: "About",
    component: About,
    private: false,
    path: "/about",
    exact: true,
    hideonauth: false,
  },
  {
    name: "Blogs",
    component: Blogs,
    private: false,
    path: "/blogs",
    exact: true,
    hideonauth: false,
  },
  {
    name: "Researchs",
    component: Researchs,
    private: false,
    path: "/researchs",
    exact: true,
    hideonauth: false,
  },
  {
    name: "Investor Login",
    component: InvestorLogin,
    private: false,
    path: "/investor_login",
    exact: true,
    hideonauth: false,
  },
];
