import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../ui/Button";
import { StyledInputGroup, ErrorMessage } from "../../globalStyles";
import styled from "styled-components";
import { sendemail } from "../../utilities";

const Heading = styled.h4`
  font-family: ${({ theme }) => theme.fonts.navigations};
  margin-bottom: 0.4rem;
`;
function InvetForm(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const handleFormSubmit = (data) => {
    setLoading(true);
    sendemail(
      {
        name: data.firstname + " " + data.lastname,
        email: data.email,
        city: data.city,
        companyName: data.companyName,
        jobTitle: data.jobTitle,
        phone: data.areaCode + " " + data.phone,
        investorType: data.investorType,
      },
      () => {
        setLoading(false);
        setSent(true);
        setTimeout(() => {
          reset();
          setSent(false);
        }, 1500);
      }
    );
  };
  return (
    <form>
      <div className="d-flex flex-wrap align-items-center my-4">
        <Heading className="col-12 col-md-3">Name</Heading>
        <StyledInputGroup className="col-12 col-md-4" error={errors.firstname}>
          <input {...register("firstname", { required: true })} />
          <label>First Name</label>
          {errors.firstname && (
            <ErrorMessage>This field is required</ErrorMessage>
          )}
        </StyledInputGroup>
        <div className="col-0 col-md-1"></div>
        <StyledInputGroup className="col-12 col-md-4" error={errors.lastname}>
          <input {...register("lastname", { required: true })} />
          <label>Last Name</label>
          {errors.lastname && (
            <ErrorMessage>This field is required</ErrorMessage>
          )}
        </StyledInputGroup>
      </div>
      <div className="d-flex flex-wrap align-items-center my-4">
        <Heading className="col-12 col-md-3">Email</Heading>
        <StyledInputGroup className="col-12 col-md-9" error={errors.email}>
          <input
            {...register("email", {
              required: "This field is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            })}
          />
          {errors.email && <ErrorMessage>{errors.email?.message}</ErrorMessage>}
        </StyledInputGroup>
      </div>
      <div className="d-flex flex-wrap align-items-center my-4">
        <Heading className="col-12 col-md-3">City</Heading>
        <StyledInputGroup className="col-12 col-md-9" error={errors.city}>
          <input {...register("city", { required: true })} />
          {errors.city && <ErrorMessage>This field is required</ErrorMessage>}
        </StyledInputGroup>
      </div>
      <div className="d-flex flex-wrap align-items-center my-4">
        <Heading className="col-12 col-md-3">Company</Heading>
        <StyledInputGroup
          className="col-12 col-md-4"
          error={errors.companyName}
        >
          <input {...register("companyName", { required: true })} />
          <label>Company Name</label>
          {errors.companyName && (
            <ErrorMessage>This field is required</ErrorMessage>
          )}
        </StyledInputGroup>
        <div className="col-0 col-md-1"></div>
        <StyledInputGroup className="col-12 col-md-4" error={errors.jobTitle}>
          <input {...register("jobTitle", { required: true })} />
          <label>Job Title</label>
          {errors.jobTitle && (
            <ErrorMessage>This field is required</ErrorMessage>
          )}
        </StyledInputGroup>
      </div>
      <div className="d-flex flex-wrap align-items-center my-4">
        <Heading className="col-12 col-md-3">Phone</Heading>
        <StyledInputGroup className="col-2" error={errors.areaCode}>
          <input {...register("areaCode", { required: true })} />
          <label>Area Code</label>
          {errors.areaCode && <ErrorMessage>Required</ErrorMessage>}
        </StyledInputGroup>
        <div className="col-1"></div>
        <StyledInputGroup
          className="col-9 col-md-6"
          error={errors.phone}
          style={{ marginBottom: "auto" }}
        >
          <input
            {...register("phone", {
              required: "This field is required",
              pattern: {
                value: /^\d+$/i,
                message: "Invalid Number",
              },
            })}
          />
          <label>Phone Number</label>
          {errors.phone && <ErrorMessage>{errors.phone?.message}</ErrorMessage>}
        </StyledInputGroup>
      </div>
      <div className="d-flex flex-wrap align-items-center my-4">
        <Heading className="col-12 col-md-3">Investor Type</Heading>
        <StyledInputGroup
          className="col-12 col-md-9"
          error={errors.investorType}
        >
          <select {...register("investorType", { required: true })}>
            <option value="" selected disabled hidden>
              Choose here
            </option>
            <option value="Advisor">Advisor</option>
            <option value="Individual Investor">Individual Investor</option>
            <option value="Institutional Investor">
              Institutional Investor
            </option>
          </select>
          {errors.investorType && (
            <ErrorMessage>This field is required</ErrorMessage>
          )}
        </StyledInputGroup>
      </div>
      <div className="d-flex justify-content-end">
        <Button
          text={
            loading || sent ? (sent ? "Submitted" : "Submitting...") : "Submit"
          }
          onclick={handleSubmit(handleFormSubmit)}
          disabled={loading || sent}
        />
      </div>
    </form>
  );
}

export default InvetForm;
